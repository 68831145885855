import { NavMobile } from "../NavMobile/nav-mobile";
import { NavDesktop } from "../NavDesktop/nav-desktop";
import Confirmation from "../Modal/Confirmation";
import { handleSignOut } from "../../config/database/authentication";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";
import { setIsMicOn } from "../../store/apps/mic";
import MyProfile from "../Modal/MyProfile";
import ChangePassword from "../Modal/changePassword";
import { moderateScale } from "../../util/utils";

export const TopBar = ({ appearance, assistantData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const navigate = useNavigate();

  const handleClicked = (title, href) => {
    switch (title) {
      case "Logout":
        setIsModalOpen(true);
        break;
      case "Speaking":
        localStorage.setItem("isMicOn", href);
        store.dispatch(setIsMicOn(href));
        break;
      case "Mute":
        localStorage.setItem("isMicOn", href);
        store.dispatch(setIsMicOn(href));
        break;
      case "My Profile":
        setIsProfileModalOpen(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div
        style={{ background: appearance?.headerBackgroundColor || "#c4d8e4" }}
        className="relative fixed top-0 left-0 right-0"
      >
        <nav className="mx-auto px-4 lg:px-0 w-full md:w-[70%] flex items-center justify-between py-1 lg:py-4">
          <span
            style={{
              color: appearance?.headerTitleColor || "#000000",
              fontSize: moderateScale(30, appearance?.headerTitleFontSize || 1),
            }}
            className="font-poppins font-[700] overflow-hidden break-words truncate"
          >
            {assistantData?.displayAssistantName
              ? assistantData?.assistantName || "Lara"
              : "Lara"}
          </span>
          <NavMobile handleClicked={handleClicked} appearance={appearance} />
          <NavDesktop handleClicked={handleClicked} appearance={appearance} />
        </nav>
      </div>
      <Confirmation
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        onConfirm={() => {
          handleSignOut({ navigate });
        }}
      />
      <MyProfile
        showModal={isProfileModalOpen}
        setShowModal={setIsProfileModalOpen}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
      <ChangePassword
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
        setIsProfileModalOpen={setIsProfileModalOpen}
      />
    </>
  );
};
