/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { getChatAnswer, fetchSpeech } from "../../api/chat";
import { getChatConfigs, getSpeechConfigs } from "../../config/database/chat";
import "./choice.css";
import Typewriter from "../../components/Typewriter";
import { YES, NO } from "../../assets/Images";
import { useSelector } from "react-redux";
import { getChat } from "../../store/apps/chat";
import backgroundImage from "../../assets/Images/choice-new-background.png";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

const Choice = () => {
  const chat = useSelector(getChat);
  const [messages, setMessages] = useState([]);
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [typewriter, setTypewriter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCrossHovered, setIsCrossHovered] = useState(false);
  const [readingAiResponse, setReadingAiResponse] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isCrossActive, setIsCrossActive] = useState(false);
  const [animatePing, setAnimatePing] = useState(false);
  const [crossAnimatePing, setCrossAnimatePing] = useState(false);

  // Refs
  const divRef = useRef(null);
  const chatConfigDataRef = useRef(null);
  const speechConfigDataRef = useRef(null);
  const pendingMessagesRef = useRef(null);
  const scrollRef = useRef(null);

  // Speech Config Data
  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      speechConfigDataRef.current = configData;
      setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
  }, []);

  // Chat Config Data
  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      chatConfigDataRef.current = data;
    };

    fetchChatConfig();
  }, []);

  // Height Update
  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      const typingMessage = {
        role: "assistant",
        content: "Typing...",
        typing: true,
      };

      setTypewriter(true);
      setReadingAiResponse(true);
      setMessages([typingMessage]);
      pendingMessagesRef.current = [...messages, ...chat];
      speakMessage(chat[chat.length - 1], chat.length - 1);
    }
  }, [chat, speechConfigData]);

  useEffect(() => {
    isActive && setIsActive(readingAiResponse);
    isCrossActive && setIsCrossActive(readingAiResponse);
  }, [readingAiResponse]);

  const sendMessage = async (data) => {
    if (!data) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing..."
    ) {
      return;
    }

    const userMessage = {
      role: "user",
      content: data,
    };
    const newMessages = [...messages, userMessage];

    // Typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Typing...",
      typing: true,
    };

    setMessages([typingMessage]);
    const relevantMessages = newMessages.slice(-7);

    try {
      const response = await getChatAnswer({
        messages: relevantMessages,
        ...chatConfigDataRef.current,
      });
      const assistantReply = {
        role: "assistant",
        content: response.choices[0].message.content,
      };
      const updatedMessages = [...newMessages, assistantReply];
      pendingMessagesRef.current = updatedMessages;

      speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  // Speak the response from server
  const speakMessage = async (message, index) => {
    await fetchSpeech(
      {
        text: message.content,
        ...speechConfigDataRef.current,
      },
      handleChunkFetched
    );
    setTypewriter(false);
  };

  const handleChunkFetched = () => {
    if (pendingMessagesRef.current) {
      setTypewriter(true);
      setMessages(pendingMessagesRef.current);
      pendingMessagesRef.current = null;
    }
  };

  const handleWordTyped = useCallback(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, []);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleCheck = (e) => {
    e.preventDefault();
    sendMessage("YES");
    playPingAnimation();
    setReadingAiResponse(true);
  };

  const handleCross = (e) => {
    e.preventDefault();
    playCrossPingAnimation();
    sendMessage("NO");
    setReadingAiResponse(true);
  };

  const handleMouseDown = () => {
    setIsActive(true);
    setIsHovered(false);
  };

  const handleCloseMouseDown = () => {
    setIsCrossActive(true);
    setIsCrossHovered(false);
  };

  const playPingAnimation = () => {
    setAnimatePing(true);
    setTimeout(() => {
      setAnimatePing(false);
    }, 900);
  };

  const playCrossPingAnimation = () => {
    setCrossAnimatePing(true);
    setTimeout(() => {
      setCrossAnimatePing(false);
    }, 900);
  };

  const typewriterComponent = useMemo(() => {
    return (
      <Typewriter
        texts={[messages[messages.length - 1]?.content]}
        speed={35}
        startDelay={0}
        onWordTyped={handleWordTyped}
        setReadingAiResponse={setReadingAiResponse}
        typingText={messages[messages.length - 1]?.content}
      />
    );
  }, [messages[messages.length - 1]?.content]);

  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(str, { language: lang, ignoreIllegals: true })
              .value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });

  return (
    <div className="flex flex-col">
      <div ref={divRef}></div>
      <div
        className={`flex justify-center items-center w-full
          px-[5%] md:px-[10%]`}
        style={{
          height: `calc(100vh - ${divHeight}px)`,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className={`flex justify-center items-center flex-col w-full px-[3%] md:px-[0%]`}
        >
          <div className="flex justify-center items-center w-full h-full min-h-[50vh] max-h-[70vh]">
            <div
              className="w-[100%] md:max-w-[85%] max-h-[70vh] overflow-y-auto [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-white
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-blue-900
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
              ref={scrollRef}
            >
              <p
                className={`flex items-center text-light_gray text-[16px] leading-[24px] md:text-[20px] md:leading-[32px] font-poppins font-[500] mr-[2%]`}
              >
                {messages[messages.length - 1]?.content &&
                  (messages[messages.length - 1]?.content && typewriter ? (
                    typewriterComponent
                  ) : (
                    <div
                      className={` md:mt-[5px]`}
                      dangerouslySetInnerHTML={{
                        __html: md.render(
                          messages[messages.length - 1]?.content
                        ),
                      }}
                    />
                  ))}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center mt-[5%] md:mt-[2%]">
            <div className=" flex flex-row justify-center items-center gap-[30px] md:gap-[50px]">
              <span class="relative flex justify-center items-center">
                {animatePing && (
                  <span
                    className={`absolute inline-flex h-[70%] w-[70%] ${
                      animatePing ? "animate-ping" : ""
                    } rounded-full bg-[#6949FF] opacity-100`}
                  ></span>
                )}
                <div
                  className="flex justify-center items-center  rounded-full w-[110px] h-[110px] 
              md:w-[140px] md:h-[140px] lg:w-[180px] lg:h-[180px] shadow-lg bg-[#6949FF38] overflow-hidden"
                >
                  <button
                    disabled={readingAiResponse}
                    id="check"
                    className={`flex justify-center items-center rounded-full 
              w-[80px] h-[80px] md:w-[110px] md:h-[110px] 
              lg:w-[150px] lg:h-[150px] shadow-lg bg-[#6949FF] 
              overflow-hidden transition-transform duration-300 
              ease-in-out transform  ${readingAiResponse ? "opacity-40" : ""} ${
                      isHovered ? "bg-[#785bff]" : ""
                    }`}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={handleCheck}
                  >
                    <YES
                      className=" w-[45px] h-[30px] md:w-[67px] md:h-[45px] 
              lg:w-[97px] lg:h-[65px]"
                    />
                  </button>
                </div>
              </span>

              <span class="relative flex justify-center items-center">
                {crossAnimatePing && (
                  <span
                    className={`absolute inline-flex h-[65%] w-[65%] ${
                      crossAnimatePing ? "animate-ping" : ""
                    } rounded-full bg-[#6949FF38] opacity-100`}
                  ></span>
                )}
                <div
                  className="flex justify-center items-center  rounded-full w-[110px] h-[110px] 
              md:w-[140px] md:h-[140px] lg:w-[180px] lg:h-[180px] shadow-lg bg-[#6949FF38] overflow-hidden"
                >
                  <button
                    disabled={readingAiResponse}
                    id="cross"
                    className={`flex justify-center items-center  rounded-full w-[80px] h-[80px] 
              md:w-[110px] md:h-[110px] lg:w-[150px] lg:h-[150px] shadow-lg bg-[#6949FF] overflow-hidden transition-transform duration-300 
              ease-in-out transform  ${readingAiResponse ? "opacity-40" : ""} ${
                      isCrossHovered ? "bg-[#785bff]" : ""
                    } `}
                    onMouseDown={handleCloseMouseDown}
                    onTouchStart={handleCloseMouseDown}
                    onMouseEnter={() => setIsCrossHovered(true)}
                    onMouseLeave={() => setIsCrossHovered(false)}
                    onClick={handleCross}
                  >
                    <NO
                      className=" w-[30px] h-[30px] md:w-[45px] md:h-[45px] 
              lg:w-[65px] lg:h-[65px]"
                    />
                  </button>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choice;
