import { useClickAway } from "react-use";
import { useRef } from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "../../routes";
import { moderateScale } from "../../util/utils";

export const NavMobile = ({ handleClicked, appearance }) => {
  const isScreenMdOrLarger = window.innerWidth >= 768;
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  const getTextAndIconColor = (title) => {
    switch (title) {
      case "Logout":
        return appearance?.logoutColor || "#000000";
      case "Speaking":
        return appearance?.speakingColor || "#000000";
      case "Mute":
        return appearance?.muteColor || "#000000";
      case "My Profile":
        return appearance?.myProfileColor || "#000000";
      default:
        return "#000000";
    }
  };

  const getTextAndIconSize = (title) => {
    switch (title) {
      case "Logout":
        return moderateScale(
          isScreenMdOrLarger ? 25 : 20,
          appearance?.logoutFontSize || 1
        );
      case "Speaking":
        return moderateScale(
          isScreenMdOrLarger ? 25 : 20,
          appearance?.speakingFontSize || 1
        );
      case "Mute":
        return moderateScale(
          isScreenMdOrLarger ? 25 : 20,
          appearance?.muteFontSize || 1
        );
      case "My Profile":
        return moderateScale(
          isScreenMdOrLarger ? 25 : 20,
          appearance?.myProfileFontSize || 1
        );
      default:
        return isScreenMdOrLarger ? 25 : 20;
    }
  };

  const getTextSize = (title) => {
    switch (title) {
      case "Logout":
        return moderateScale(16, appearance?.logoutFontSize || 1);
      case "Speaking":
        return moderateScale(16, appearance?.speakingFontSize || 1);
      case "Mute":
        return moderateScale(16, appearance?.muteFontSize || 1);
      case "My Profile":
        return moderateScale(16, appearance?.myProfileFontSize || 1);
      default:
        return 16;
    }
  };

  return (
    <div ref={ref} className="lg:hidden ">
      <Hamburger
        toggled={isOpen}
        size={moderateScale(
          isScreenMdOrLarger ? 25 : 20,
          appearance?.hamburgerIconSize || 1
        )}
        toggle={setOpen}
        color={appearance?.hamburgerIconColor || "#000000"}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 p-3 pt-0 z-50 md:px-[16%]"
            style={{
              background: appearance?.headerBackgroundColor || "#c4d8e4",
            }}
          >
            <ul className="grid gap-2">
              {routes.map((route, idx) => {
                const { Icon } = route;

                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    style={{
                      background: `linear-gradient(to top right, #a3a3a3, ${
                        appearance?.headerBackgroundColor || "#c4d8e4"
                      }, ${getTextAndIconColor(route.title)})`,
                    }}
                    className="w-full p-[0.08rem] rounded-xl "
                  >
                    <div
                      onClick={() => {
                        setOpen((prev) => !prev);
                        handleClicked(route.title, route.href);
                      }}
                      style={{
                        color: getTextAndIconColor(route.title),
                        background:
                          appearance?.headerBackgroundColor || "#c4d8e4",
                      }}
                      className={
                        "flex items-center justify-between w-full p-3 rounded-xl"
                      }
                    >
                      <span
                        className="flex gap-1 font-poppins font-[500]"
                        style={{ fontSize: getTextSize(route.title) }}
                      >
                        {route.title}
                      </span>
                      <Icon
                        size={getTextAndIconSize(route.title)}
                        color={getTextAndIconColor(route.title)}
                      />
                    </div>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
