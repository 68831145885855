import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { ref, getStorage, getDownloadURL } from "firebase/storage";

async function getUserAppearance() {
  const userId = localStorage.getItem("uid");

  if (!userId) {
    console.warn("User ID not found in local storage.");
    return null;
  }

  try {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists()) {
      console.error("User document not found!");
      return null;
    }

    const appearanceId = userSnapshot.data()?.appearance;
    if (!appearanceId || !appearanceId.length) {
      return null;
    }
    const appearanceRef = doc(db, "appearances", appearanceId);
    const appearanceSnapshot = await getDoc(appearanceRef);

    if (appearanceSnapshot.exists()) {
      let downloadUrl;
      if (appearanceSnapshot.data()?.backgroundImage?.length > 0) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          appearanceSnapshot.data()?.backgroundImage
        );
        downloadUrl = await getDownloadURL(storageRef);
      }

      return { downloadUrl, ...appearanceSnapshot.data() };
    }
    console.error("No such document!");
    return null;
  } catch (error) {
    console.error("Error getting appearance data: ", error);
    return null;
  }
}

async function getAssistantAppearance(appearanceId) {
  try {
    const appearanceRef = doc(db, "appearances", appearanceId);
    const appearanceSnapshot = await getDoc(appearanceRef);

    if (appearanceSnapshot.exists()) {
      let downloadUrl;
      if (appearanceSnapshot.data()?.backgroundImage?.length > 0) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          appearanceSnapshot.data()?.backgroundImage
        );
        downloadUrl = await getDownloadURL(storageRef);
      }

      return { downloadUrl, ...appearanceSnapshot.data() };
    }
    console.error("No such document!");
    return null;
  } catch (error) {
    console.error("Error getting appearance data: ", error);
    return null;
  }
}

export { getUserAppearance, getAssistantAppearance };
