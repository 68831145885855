import { store } from "../store";

export const moderateScale = (size, factor = 1) => {
  let appearanceData = store.getState().appearance.appearance;

  store.subscribe(() => {
    const appearanceUpdatedData = store.getState().appearance.appearance;
    appearanceData = appearanceUpdatedData;
  });

  if (appearanceData && appearanceData?.contentScaling) {
    return size * appearanceData?.contentScalingSize;
  }

  return size * factor;
};

export const parseColor = (color) => {
  // Assume color is in hex format (e.g., #c4d8e4)
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(color)) return "196, 196, 196"; // default rgb in case of an invalid color

  // Remove the hash at the start if it's there
  color = color.slice(1);

  let r, g, b;
  if (color.length === 3) {
    // 3-digit hex
    r = parseInt(color[0] + color[0], 16);
    g = parseInt(color[1] + color[1], 16);
    b = parseInt(color[2] + color[2], 16);
  } else {
    // 6-digit hex
    r = parseInt(color.slice(0, 2), 16);
    g = parseInt(color.slice(2, 4), 16);
    b = parseInt(color.slice(4), 16);
  }
  return `${r}, ${g}, ${b}`;
};
