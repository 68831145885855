import { routes } from "../../routes";
import { moderateScale } from "../../util/utils";

export const NavDesktop = ({ handleClicked, appearance }) => {
  const getTextAndIconColor = (title) => {
    switch (title) {
      case "Logout":
        return appearance?.logoutColor || "#000000";
      case "Speaking":
        return appearance?.speakingColor || "#000000";
      case "Mute":
        return appearance?.muteColor || "#000000";
      case "My Profile":
        return appearance?.myProfileColor || "#000000";
      default:
        return "#000000";
    }
  };

  const getTextAndIconSize = (title) => {
    switch (title) {
      case "Logout":
        return moderateScale(25, appearance?.logoutFontSize || 1);
      case "Speaking":
        return moderateScale(25, appearance?.speakingFontSize || 1);
      case "Mute":
        return moderateScale(25, appearance?.muteFontSize || 1);
      case "My Profile":
        return moderateScale(25, appearance?.myProfileFontSize || 1);
      default:
        return 25;
    }
  };

  const getTextSize = (title) => {
    switch (title) {
      case "Logout":
        return moderateScale(16, appearance?.logoutFontSize || 1);
      case "Speaking":
        return moderateScale(16, appearance?.speakingFontSize || 1);
      case "Mute":
        return moderateScale(16, appearance?.muteFontSize || 1);
      case "My Profile":
        return moderateScale(16, appearance?.myProfileFontSize || 1);
      default:
        return 16;
    }
  };

  return (
    <>
      <ul className="hidden lg:flex lg:items-center gap-5">
        {routes.map((route, index) => {
          const { Icon, href, title } = route;
          return (
            <li key={index}>
              <div
                style={{
                  color: getTextAndIconColor(title),
                  cursor: "pointer",
                  transition: "color 0.3s",
                  fontSize: getTextAndIconSize(title),
                }}
                className="flex items-center gap-1"
                onClick={() => handleClicked(title, href)}
                onMouseEnter={(e) => (e.currentTarget.style.color = "#a3a3a3")}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.color = getTextAndIconColor(title))
                }
              >
                <Icon
                  color={getTextAndIconColor(title)}
                  size={getTextAndIconSize(title)}
                />
                <span
                  style={{ fontSize: getTextSize(title) }}
                  className="font-poppins font-[500]"
                >
                  {title}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
