import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  // original
  apiKey: "AIzaSyAoId1UFJGMAHHfxZL7lAuoDAyXCCDTBaE",
  authDomain: "hem-tech.firebaseapp.com",
  projectId: "hem-tech",
  storageBucket: "hem-tech.firebasestorage.app",
  messagingSenderId: "329626972707",
  appId: "1:329626972707:web:bd6a3c9016e3b9d5bc3796",

  //test-purpose
  // apiKey: "AIzaSyCcLyYmHEZY-yX-1OGYc02c_yMGJLktJek",
  // authDomain: "hem-tech-test.firebaseapp.com",
  // projectId: "hem-tech-test",
  // storageBucket: "hem-tech-test.appspot.com",
  // messagingSenderId: "831638096431",
  // appId: "1:831638096431:web:ca63b99736f89a2c3dd4da",

  // apiKey: "AIzaSyCrQwgvYrGmkAflx5H0ZCHJ7jp3Btf8308",
  // authDomain: "hem-tech-test-2.firebaseapp.com",
  // projectId: "hem-tech-test-2",
  // storageBucket: "hem-tech-test-2.appspot.com",
  // messagingSenderId: "554302382512",
  // appId: "1:554302382512:web:cd9d0182f938255a45b675",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

export { auth, db };
