import React, { useState } from "react";
import "./styles.css";
import { fetchSpeech, stopAudio } from "../../api/chat";
import { STAR_ICON } from "../../assets/Images";
import audioWaveGif from "../../assets/Images/audio-wave.gif";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import Tooltip from "@mui/material/Tooltip";
import { moderateScale, parseColor } from "../../util/utils";
import { BsRobot } from "react-icons/bs";
import { BiSolidUser } from "react-icons/bi";
import { BsRepeat } from "react-icons/bs";
import { BiStopCircle } from "react-icons/bi";
import { LuCopy } from "react-icons/lu";
import { LuFileText } from "react-icons/lu";
import { LuDownload } from "react-icons/lu";

function ChatMessage({
  message,
  speechConfigData,
  isSpeaking,
  index,
  setSpeakingStates,
  isReSpeaking,
  setIsReSpeaking,
  isMicOn,
  appearance,
  getVariationImage,
}) {
  const validImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/gif",
  ];
  const isScreenMdOrLarger = window.innerWidth >= 768;
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFetchSpeech = async (message) => {
    setIsReSpeaking({});
    setSpeakingStates({});
    if (isMicOn) {
      setIsReSpeaking((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech({ text: message.content, ...speechConfigData });
      setIsReSpeaking((prev) => ({ ...prev, [index]: false }));
    }
  };
  const handleStopFetchingSpeech = () => {
    stopAudio();
    setIsReSpeaking({});
    setSpeakingStates({});
  };
  let messageString = message.content;
  let lines = !(message.responseType === "image") && messageString.split("\n");

  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(str, { language: lang, ignoreIllegals: true })
              .value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.content).then(() => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 1000);
    });
  };

  const handleDownload = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getGridClass = (contentLength) => {
    if (contentLength === 1) return "grid grid-cols-1 gap-[10px]";
    if (contentLength === 2)
      return "grid grid-cols-1 lg:grid-cols-2 gap-[10px]";
    return "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[10px]";
  };

  return (
    <div className="mb-[20px]">
      {message.role === "assistant" ? (
        <div className="flex justify-start items-center">
          <div className="flex">
            <div
              className="flex shrink-0 justify-center items-center shadow-xl mr-[10px]"
              style={{
                borderWidth: "2px",
                borderColor:
                  appearance?.assistantChatBubbleBackgroundColor || "#2196F3",
                borderStyle: "solid",

                height: isScreenMdOrLarger
                  ? moderateScale(42, appearance?.chatFontSize || 1)
                  : moderateScale(30, appearance?.chatFontSize || 1),
                width: isScreenMdOrLarger
                  ? moderateScale(42, appearance?.chatFontSize || 1)
                  : moderateScale(30, appearance?.chatFontSize || 1),
                borderRadius: isScreenMdOrLarger
                  ? moderateScale(21, appearance?.chatFontSize || 1)
                  : moderateScale(15, appearance?.chatFontSize || 1),
                padding: moderateScale(5, appearance?.chatFontSize || 1),
              }}
            >
              <BsRobot
                color={
                  appearance?.assistantChatBubbleBackgroundColor || "#2196F3"
                }
                className="flex w-full h-full"
              />
            </div>
            <div
              style={{
                background:
                  message.type && message.type === "error"
                    ? `rgba(${parseColor("#e34e3d")}, ${String(
                        (appearance?.assistantChatBubbleBackgroundOpacity ||
                          100) / 100
                      )})`
                    : `rgba(${parseColor(
                        appearance?.assistantChatBubbleBackgroundColor ||
                          "#2196F3"
                      )}, ${String(
                        (appearance?.assistantChatBubbleBackgroundOpacity ||
                          100) / 100
                      )})`,
                fontSize: isScreenMdOrLarger
                  ? moderateScale(16, appearance?.chatFontSize || 1)
                  : moderateScale(16, appearance?.chatFontSize || 1),
              }}
              className={`flex flex-col p-[10px] font-poppins font-[400] rounded-r-1x rounded-bl-1x shadow-assist mr-[12px] md:mr-[15px]`}
            >
              {message.responseType === "image" ? (
                <div className={getGridClass(message?.content.length)}>
                  {message?.content?.map((msg, index) => (
                    <div
                      key={index}
                      className="relative flex flex-col"
                      style={{ minHeight: "128px", minWidth: "128px" }}
                    >
                      <div
                        className="absolute top-2 right-2 mt-[5px] mr-[5px] cursor-pointer z-10 bg-stone-200 p-[5px] rounded-md"
                        onClick={() => handleDownload(msg.url)}
                      >
                        <Tooltip title={"Download"}>
                          <LuDownload
                            color={"#000000"}
                            size={
                              isScreenMdOrLarger
                                ? moderateScale(25, 1)
                                : moderateScale(20, 1)
                            }
                          />
                        </Tooltip>
                      </div>
                      <div
                        className="absolute top-2 left-2 mt-[5px] mr-[5px] cursor-pointer z-10 bg-stone-200 p-[5px] rounded-md"
                        onClick={() => {
                          getVariationImage(msg.url);
                        }}
                      >
                        <Tooltip title={"Generate Variation"}>
                          <STAR_ICON className="md:w-[25px] md:h-[25px] w-[20px] h-[20px]" />
                        </Tooltip>
                      </div>
                      <img
                        className="rounded-1x max-h-96"
                        key={index}
                        src={msg.url}
                        alt={msg.revised_prompt}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                lines.map((line, index) => {
                  const markdownLine = md.render(line);
                  return (
                    <div
                      key={index}
                      className={`${
                        lines.length === index + 1 ? "" : ""
                      } md:mt-[5px]`}
                      style={{
                        color: appearance?.assistantChatTextColor || "#FCFCFC",
                      }}
                      dangerouslySetInnerHTML={{ __html: markdownLine }}
                    />
                  );
                })
              )}
            </div>
          </div>

          {message.responseType !== "image" && (
            <div
              onClick={() => {
                (isReSpeaking || isSpeaking) && isHovered
                  ? handleStopFetchingSpeech()
                  : handleFetchSpeech(message);
              }}
              className={`shrink-0 ${
                !(isReSpeaking || isSpeaking) && "cursor-pointer"
              }`}
              style={{
                width: moderateScale(30, appearance?.chatFontSize || 1),
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {message.typing === true ? (
                <div className="container">
                  <div className="dot" />
                </div>
              ) : isReSpeaking || isSpeaking ? (
                isHovered ? (
                  <BiStopCircle
                    color={"#000000"}
                    size={
                      isScreenMdOrLarger
                        ? moderateScale(25, appearance?.chatFontSize || 1)
                        : moderateScale(20, appearance?.chatFontSize || 1)
                    }
                  />
                ) : (
                  <img
                    src={audioWaveGif}
                    alt="wave"
                    style={{
                      height: isScreenMdOrLarger
                        ? moderateScale(30, appearance?.chatFontSize || 1)
                        : moderateScale(25, appearance?.chatFontSize || 1),
                      width: isScreenMdOrLarger
                        ? moderateScale(30, appearance?.chatFontSize || 1)
                        : moderateScale(25, appearance?.chatFontSize || 1),
                    }}
                  />
                )
              ) : (
                <BsRepeat
                  color={"#000000"}
                  size={
                    isScreenMdOrLarger
                      ? moderateScale(25, appearance?.chatFontSize || 1)
                      : moderateScale(20, appearance?.chatFontSize || 1)
                  }
                  className="rotate-90"
                />
              )}
            </div>
          )}
          {!message.typing && message.responseType !== "image" && (
            <Tooltip
              title="Copied"
              open={showTooltip}
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <div
                className="ml-[5px] cursor-pointer"
                onClick={copyToClipboard}
              >
                <LuCopy
                  color={"#000000"}
                  size={
                    isScreenMdOrLarger
                      ? moderateScale(25, appearance?.chatFontSize || 1)
                      : moderateScale(20, appearance?.chatFontSize || 1)
                  }
                />
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="flex justify-end">
          <div className="flex">
            <div
              style={{
                background: `rgba(${parseColor(
                  appearance?.userChatBubbleBackgroundColor || "#26A69A"
                )}, ${String(
                  (appearance?.userChatBubbleBackgroundOpacity || 100) / 100
                )})`,
                color: appearance?.userChatTextColor || "#FCFCFC",
                fontSize: isScreenMdOrLarger
                  ? moderateScale(16, appearance?.chatFontSize || 1)
                  : moderateScale(16, appearance?.chatFontSize || 1),
              }}
              className={`flex flex-col items-end justify-center px-[10px] py-[10px] font-poppins font-[400] rounded-l-1x rounded-br-1x shadow-user ml-[40px] md:ml-[50px] ${
                message.typing ? "typing-indicator" : ""
              }}`}
            >
              {message.file && (
                <>
                  <div className="flex flex-row items-center">
                    {validImageTypes.includes(message.file.type) ? (
                      <img
                        src={URL.createObjectURL(message.file)}
                        alt="Selected"
                        className=" w-auto h-auto max-h-[75px] rounded-[5px]"
                      />
                    ) : (
                      <LuFileText
                        color={"#000000"}
                        size={
                          isScreenMdOrLarger
                            ? moderateScale(25, 1)
                            : moderateScale(20, 1)
                        }
                      />
                    )}
                    <p className="ml-[6px]">{message.file.name}</p>
                  </div>
                  <div
                    className="h-[1px] w-full my-[5px]"
                    style={{
                      backgroundColor:
                        appearance?.userChatTextColor || "#FCFCFC",
                    }}
                  />
                </>
              )}
              <span>{message.content}</span>
            </div>
            <div
              style={{
                borderWidth: "2px",
                borderColor:
                  appearance?.userChatBubbleBackgroundColor || "#26A69A",
                borderStyle: "solid",

                height: isScreenMdOrLarger
                  ? moderateScale(42, appearance?.chatFontSize || 1)
                  : moderateScale(30, appearance?.chatFontSize || 1),
                width: isScreenMdOrLarger
                  ? moderateScale(42, appearance?.chatFontSize || 1)
                  : moderateScale(30, appearance?.chatFontSize || 1),
                borderRadius: isScreenMdOrLarger
                  ? moderateScale(21, appearance?.chatFontSize || 1)
                  : moderateScale(15, appearance?.chatFontSize || 1),
                padding: moderateScale(5, appearance?.chatFontSize || 1),
              }}
              className="flex shrink-0 justify-center items-center shadow-xl ml-[10px]"
            >
              <BiSolidUser
                color={appearance?.userChatBubbleBackgroundColor || "#26A69A"}
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatMessage;
