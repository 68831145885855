// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "appearance",
  initialState: {
    appearance: null,
  },
  reducers: {
    setAppearanceData: (state, action) => {
      state.appearance = action.payload;
    },
  },
});

export const { setAppearanceData } = counterSlice.actions;

export default counterSlice.reducer;

export const getAppearance = (state) => state.appearance.appearance;
